import { useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import toast from "../../utils/toast.utils";
import { currentUserStore } from "../../store/current-user.store";
import { useTranslate } from "../../i18n/translation.i18n";

const UserChangePasswordPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const submitUpdate = () => {
    if (oldPassword.trim().length <= 5 || newPassword.trim().length <= 5) {
      return toast.dispatch("alert", "form info", "Field email is required.");
    }
    setDisabled(true);
    setIsPending(true);

    userService
      .changePassword({
        email: currentUserStore.snapshot.email,
        oldPassword,
        newPassword,
      })
      .then(() => {
        setIsPending(false);

        toast.dispatch(
          "success",
          "Success",
          "Done! Your password has been changed successfully."
        );

        navigate("/user/profile");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Update error", err.message || err);
      });
  };

  return (
    <div id="change-email-page" style={{ flexDirection: "column" }}>
      <h3>{translate.pages.userChangePassword.h3_1}</h3>
      <div id="change-email-form">
        <span className="input">
          <label htmlFor="old-password">
            {translate.pages.userChangePassword.label_1}
          </label>
          <input
            className="input-text"
            type="text"
            id="old-password"
            value={oldPassword}
            onChange={({ target }) => setOldPassword(target.value)}
          />
        </span>
        <span className="input">
          <label htmlFor="new-password">
            {translate.pages.userChangePassword.label_2}
          </label>
          <input
            className="input-text"
            type="text"
            id="new-password"
            value={newPassword}
            onChange={({ target }) => setNewPassword(target.value)}
          />
        </span>
        <button
          className="auth-button activation-button"
          onClick={submitUpdate}
          disabled={disabled}
        >
          {translate.pages.userChangePassword.button} {isPending && " ..."}
        </button>
      </div>
    </div>
  );
};

export default UserChangePasswordPage;
