import { useState } from "react";
import ToastItemComponent from "./toast-item.component";
import { currentUserStore } from "../../store/current-user.store";

const ToastComponent = () => {
  const [toastList, setToastList] = useState([]);

  currentUserStore.listenAction("toast-message", ({ data }) => {
    const newList = [...toastList];
    newList.push(data);
    setToastList(newList);
  });

  const removeToast = (index) => {
    const newList = [...toastList];
    newList.splice(index, 1);
    setToastList(() => newList);
  };

  return (
    <div id="toast-container">
      {toastList.map((toast, index) => (
        <ToastItemComponent
          key={index}
          data={toast}
          handleClose={() => removeToast(index)}
          handleClick={() => removeToast(index)}
          handleDissapear={() => removeToast(index)}
        />
      ))}
    </div>
  );
};

export default ToastComponent;
