export const sw = {
  registerServiceWorker,
};

function registerServiceWorker(
  /** @type { (registration: ServiceWorkerRegistration) => void } */ successCb = null,
  /** @type { (error: any) => void } */ errorCb = null,
  /** @type { RegistrationOptions } */ options = {}
) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/serviceWorker.js", options)
      .then((registration) => {
        typeof successCb === "function" && successCb(registration);
      })
      .catch((err) => {
        typeof errorCb === "function" && errorCb(err);
      });
  }
}
