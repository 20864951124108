import { useState } from "react";
import toast from "../../utils/toast.utils";
import transactionService from "../../services/transaction.service";
import { useTranslate } from "../../i18n/translation.i18n";
import { Link } from "react-router-dom";

const TrackTransactionPage = () => {
  const { translate } = useTranslate();
  const [transactionCode, setTransactionCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [status, setStatus] = useState(null);
  const [tId, setTransactionId] = useState(null);

  const submit = () => {
    if (transactionCode.length === 0) {
      return toast.dispatch(
        "alert",
        "form info",
        "A transaction code is required."
      );
    }
    setDisabled(true);
    setIsPending(true);

    transactionService
      .trackTransaction({ transactionCode })
      .then(({ transactionCode, transactionId, transactionStatus }) => {
        setStatus(transactionStatus);
        setIsPending(false);
        setDisabled(false);
        setTransactionId(transactionId);
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        setStatus(null);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  return (
    <div
      id="track-transaction-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "15px 0",
      }}
    >
      <strong
        align="center"
        style={{
          display: "inline-block",
          width: "100%",
          margin: "10px 0 20px 0",
        }}
      >
        {translate.pages.trackTransaction.strong}
      </strong>
      <span className="input" style={{ width: "80%" }}>
        <input
          className="input-text"
          type="text"
          id="transaction-code"
          placeholder={translate.pages.trackTransaction.inputPlaceholder_1}
          value={transactionCode}
          style={{ textAlign: "center" }}
          onChange={({ target }) => setTransactionCode(target.value)}
        />
      </span>
      <button
        className="button activation-button"
        style={{ width: "80%" }}
        onClick={submit}
        disabled={disabled}
      >
        {translate.pages.trackTransaction.button} {isPending && " ..."}
      </button>
      {!!status && (
        <p>
          {translate.pages.trackTransaction.statusText} :{" "}
          <b>{translate.transactionStatus[status]}</b>
        </p>
      )}
      {!!tId && (
        <Link to={"/transactions/" + tId + "/details"}>
          {translate.pages.trackTransaction.viewTransaction}
        </Link>
      )}
    </div>
  );
};

export default TrackTransactionPage;
