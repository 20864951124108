/**
 * @typedef {{
 *   path: string;
 *   page: React.FunctionComponent | React.Component;
 *   children?: RouteList;
 *   data?: any;
 *   searchParams?: Record<string, string>;
 *   canActivate?: { () => boolean } | boolean;
 *   redirectOnFail?: string;
 *   redirectUrl?: string;
 *   name: string;
 * }} Route
 * @typedef { Array<Route> } RouteList
 */

import TrackTransactionPage from "../pages/transaction/track-transaction.page";
import TransactionPage from "../pages/transaction/transaction.page";
import TransactionsPage from "../pages/transaction/transactions.page";
import { currentUserStore } from "../store/current-user.store";

/** @type { RouteList } */
const transactionRoutes = [
  {
    path: "/transactions",
    page: TransactionsPage,
    canActivate() {
      return !!currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/auth/login",
  },
  {
    path: "/transactions/track",
    page: TrackTransactionPage,
    canActivate() {
      return !!currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/auth/login",
  },
  {
    path: "/transactions/:transactionId/details",
    page: TransactionPage,
    canActivate() {
      return !!currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/auth/login",
  },
];

export default transactionRoutes;
