import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentUserStore } from "../../store/current-user.store";
import toast from "../../utils/toast.utils";
import userService from "../../services/user.service";
import authService from "../../services/auth.service";
import { useTranslate } from "../../i18n/translation.i18n";

const UserChangeEmailPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [email, setEmail] = useState(currentUserStore.snapshot.email);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const submitUpdate = () => {
    if (email.length === 0 || password.length === 0) {
      return toast.dispatch("alert", "form info", "Field email is required.");
    }
    setDisabled(true);
    setIsPending(true);

    userService
      .changeEmail({
        currentEmail: currentUserStore.snapshot.email,
        newEmail: email,
      })
      .then(() => {
        setIsPending(false);

        const storage = !!sessionStorage.currentUser
          ? sessionStorage
          : localStorage;
        storage.removeItem("currentUser");
        storage.removeItem("authToken");

        authService.login({ email, password }).then((currenUser) => {
          currentUserStore.dispatch("login", currenUser).then(() => {
            storage.setItem("currentUser", JSON.stringify(currenUser));
            storage.setItem("authToken", currenUser.token);
            setIsPending(false);

            toast.dispatch(
              "success",
              "Success",
              "Done! Your email has been changed successfully."
            );

            return navigate("/user/profile");
          });
        });
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Update error", err.message || err);
      });
  };

  const togglePassworInputType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <div id="change-email-page" style={{ flexDirection: "column" }}>
      <h3>{translate.pages.userChangeEmail.h3_1}</h3>
      <div id="change-email-form">
        <span className="input">
          <label htmlFor="email">
            {translate.pages.userChangeEmail.label_1}
          </label>
          <input
            className="input-text"
            type="text"
            id="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </span>
        <span className="input">
          <label htmlFor="password">
            {translate.pages.userChangeEmail.label_2}
          </label>
          <input
            className="input-text password"
            type={passwordType}
            id="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
          <span onClick={togglePassworInputType}>
            {passwordType === "password"
              ? translate.pages.userChangeEmail.show
              : translate.pages.userChangeEmail.hide}
          </span>
        </span>
        <button
          className="auth-button activation-button"
          onClick={submitUpdate}
          disabled={disabled}
        >
          {translate.pages.userChangeEmail.button} {isPending && " ..."}
        </button>
      </div>
    </div>
  );
};

export default UserChangeEmailPage;
