/**
 * @typedef {{
*   path: string;
*   page: React.FunctionComponent | React.Component;
*   children?: RouteList;
*   data?: any;
*   searchParams?: Record<string, string>;
*   canActivate?: { () => boolean } | boolean;
*   redirectOnFail?: string;
*   redirectUrl?: string;
*   name: string;
* }} Route
* @typedef { Array<Route> } RouteList
*/

import UserChangeEmailPage from "../pages/user/user-change-email.page";
import UserChangePasswordPage from "../pages/user/user-change-password.page";
import UserProfilePage from "../pages/user/user-profile.page";
import { currentUserStore } from "../store/current-user.store";

/** @type { RouteList } */
const userRoutes = [
 {
   path: "/user/profile",
   page: UserProfilePage,
   canActivate() {
     return !!currentUserStore.snapshot.userId;
   },
   redirectOnFail: "/auth/login"
 },
 {
  path: "/user/change-password",
  page: UserChangePasswordPage,
  canActivate() {
    return !!currentUserStore.snapshot.userId;
  },
  redirectOnFail: "/auth/login"
},
{
  path: "/user/change-email",
  page: UserChangeEmailPage,
  canActivate() {
    return !!currentUserStore.snapshot.userId;
  },
  redirectOnFail: "/auth/login"
},
];

export default userRoutes;
