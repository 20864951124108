import { useState } from "react";
import supportedLangConfig from "../config/supported-languages.config";
import deepl from "../utils/deepl-config.utils";

const parseNavigatorLang = () => {
  return navigator.language.split("-")[0].trim();
};

export const computeLang = () => {
  const navigatorLang = navigator.language.split("-")[0];
  return supportedLangConfig.includes(navigatorLang) ? navigatorLang : "fr";
};

const currentLang = computeLang();

/** @param { string } text */
export const translate = (text) => deepl.translate(text, currentLang);

/** @type {{ en: Record<string, string>; fr: Record<string, string>; }} */
const dicos = {
  en: require("./en/en.i18n-dico.json"),
  fr: require("./fr/fr.i18n-dico.json"),
};

/** @param { string } key */
export const pickTranslation = (key) => {
  /** @type { Record<string, string> } */
  const selectedDico = dicos[currentLang] || {};

  if (!(key in selectedDico)) {
    console.error(
      "Cannot translate empty key: " + key + ". Key name is retruned."
    );
    return key;
  }

  return selectedDico[key];
};

/**
 * @param { "en" | "fr" | "de" } lang
 */
export const useTranslate = (lang = parseNavigatorLang()) => {
  const supportedLang = ["de", "en", "fr"];
  const [l, sl] = useState(supportedLang.includes(lang) ? lang : "en");
  const translations = {
    en: require("./en/en.i18n-dico.json"),
    fr: require("./fr/fr.i18n-dico.json"),
    de: require("./de/de.i18n-dico.json"),
  };
  const [t, st] = useState(translations[l]);

  /**
   * @param { "en" | "fr" | "de" } newLang
   */
  const setLang = (newLang) => {
    if (supportedLang.includes(lang)) {
      sl(newLang);
    } else {
      alert("Not supported language.");
      return;
    }
    st(translations[l]);
  };

  return { translate: t, setLang };
};
