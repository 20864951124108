import { useState } from "react";
import { currentUserStore } from "../store/current-user.store";
import { Link } from "react-router-dom";
import userService from "../services/user.service";
import toast from "../utils/toast.utils";
import { useTranslate } from "../i18n/translation.i18n";

const HeaderComponent = () => {
  const { translate } = useTranslate();
  const staticLinks = [];

  const getNotAuthLinks = () => {
    return [
      ...staticLinks,
      {
        className: "app-header-link",
        title: translate.components.header.title_5,
        link: "/auth/login",
        key: ""
      },
    ];
  };

  const getAuthLinks = () => {
    return [
      ...staticLinks,
      {
        className: "app-header-link",
        title: translate.components.header.title_6,
        link: "/user/profile",
        key: ""
      },
      {
        className: "app-header-link",
        title: translate.components.header.title_8,
        link: "/transactions/track",
        key: ""
      },
      {
        className: "app-header-link",
        title: translate.components.header.title_9,
        link: "/transactions",
        key: ""
      },
      {
        className: "app-header-link",
        title: translate.components.header.title_7,
        link: "/auth/login",
        key: "logout"
      },
    ];
  };

  const [links, setLinks] = useState(
    !!currentUserStore.snapshot.userId ? getAuthLinks() : getNotAuthLinks()
  );

  currentUserStore.addActionListener("login", () => {
    setLinks(getAuthLinks());
  });
  currentUserStore.addActionListener("logout", () => {
    setLinks(getNotAuthLinks());
  });

  const toggleMenu = () => {
    document
      .querySelector("#app-header-links")
      .classList.toggle("list-disabled");
  };

  const handleClick = (value) => {
    toggleMenu();
    if (value === "logout") {
      userService.logout().then(() => {
        if (localStorage.currentUser) {
          localStorage.removeItem("currentUser");
          localStorage.removeItem("authToken");
        } else {
          sessionStorage.removeItem("currentUser");
          sessionStorage.removeItem("authToken");
        }
        toast.dispatch("success", "Success", "You're logged out.");
        currentUserStore.dispatch("logout").then(() => {
          window.location.reload();
        });
      });
    }
  };

  return (
    <>
      <header id="header-app">
        <Link to="/" id="app-title">
          <span>Diaspo Remit Admin</span>
        </Link>
        <span id="app-header-links" className="list-disabled">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.link}
              onClick={() => handleClick(link.key)}
            >
              <span className={link.className}>{link.title}</span>
            </Link>
          ))}
        </span>
        <span id="menu-toggle" onClick={toggleMenu}></span>
      </header>
    </>
  );
};

export default HeaderComponent;
