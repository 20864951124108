import { currentUserStore } from "../store/current-user.store";

const toast = {
  dispatch,
};

/**
 * @param { 'error' | 'info' | 'success' | 'alert' } toastType
 * @param { string } toastTitle
 * @param { string } toastMessage
 * @param { number } interval
 */
function dispatch(toastType, toastTitle, toastMessage, interval = 10) {
  currentUserStore.emit("toast-message", {
    toastType,
    toastTitle,
    toastMessage,
    interval,
  });
}

export default toast;
