import { useTranslate } from "../../i18n/translation.i18n";
import toast from "../../utils/toast.utils";

const CopyCodeComponent = ({ transactionCode, transactionId, closeModal }) => {
  const { translate } = useTranslate();
  const copyCode = (e) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(transactionCode.trim())
      .then(() => {
        closeModal();
        toast.dispatch("info", "Copy", "Transaction code copied.");
      })
      .catch((err) => {
        toast.dispatch(
          "error",
          "Copy error",
          "Cannot copy text to the clipboard. Copy manually."
        );
      });
  };

  return (
    <div id="copy-code-component">
      <span className="text">
        <span>{transactionCode}</span>
        <button onClick={copyCode}>
          {translate.components.copyModal.button}
        </button>
      </span>
      <hr />
      <p>{translate.components.copyModal.p_1}</p>
      <p>
        <b>Interact Transfer</b>
        <br />
        <b>Sofortüberweisung</b>
        <br />
        <b>Zelle</b>
      </p>
      <p>
        {translate.components.copyModal.p_2}{" "}
        <b>{translate.components.copyModal.b_1}</b>,
        {translate.components.copyModal.p_3}{" "}
        <b>{translate.components.copyModal.b_2}</b>.
      </p>
    </div>
  );
};

export default CopyCodeComponent;
