/**
 * @typedef {{
 *   path: string;
 *   page: React.FunctionComponent | React.Component;
 *   children?: RouteList;
 *   data?: any;
 *   searchParams?: Record<string, string>;
 *   canActivate?: { () => boolean } | boolean;
 *   redirectOnFail?: string;
 *   redirectUrl?: string;
 *   name: string;
 * }} Route
 * @typedef { Array<Route> } RouteList
 */

import { Navigate, Route } from "react-router-dom";

/**
 * @param { RouteList } routeList
 *
 * @return { Array<Omit<Route, "children">> }
 */
const parseRoutes = (routeList) => {
  /** @type { Array<Omit<Route, "children">> } */
  const computedRoutes = [];

  for (let route of routeList) {
    if (route.children) {
      const subRoutes = parseRoutes(route);
      if (!!route.name) {
        subRoutes.map((item) => {
          if (!item.name) {
            return item;
          }
          item.name = route.name + "." + item.name;
          return item;
        });
      }

      computedRoutes.push(
        ...subRoutes.map((item) => {
          item.path = route.path + item.path;
          return item;
        })
      );
    } else {
      computedRoutes.push(route);
    }
  }

  return computedRoutes;
};

/**
 * @param { Array<{ Route }> } routeList
 *
 * @return { JSX.Element }
 */
const injectRoutes = (routeList) => {
  const computeRoutes = parseRoutes(routeList);
  return (
    <>
      {computeRoutes.map(
        ({
          data,
          name,
          page: Page,
          path,
          canActivate,
          redirectOnFail,
          redirectUrl,
          searchParams,
          ...rest
        }, index) => (
          <Route
            path={path}
            key={index}
            Component={function () {
              if (redirectUrl) {
                return <Navigate to={redirectOnFail} />;
              }

              if (!!canActivate) {
                if (
                  (typeof canActivate === "boolean" && !canActivate) ||
                  !canActivate()
                ) {
                  if (!redirectOnFail) {
                    throw new Error(
                      "Property redirectOnFail is required when canActivate fails."
                    );
                  }
                  return <Navigate to={redirectOnFail} />;
                }
              }

              return (
                <Page
                  routedata={{
                    name,
                    data,
                    redirectOnFail,
                    redirectUrl,
                    searchParams,
                  }}
                />
              );
            }}
            {...rest}
          />
        )
      )}
    </>
  );
};

export default injectRoutes;
