import { api } from "../utils/api-config.utils";

const basePath = "/auth";
const authService = {
  login,
  signUp,
  activateAccount,
  resendActivationCode,
  resendPasswordCode,
  initResetPassword,
  verifyPasswordCode,
  resetPassword,
};

/**
 * @param {{ email: string; password: string; }} body
 */
function login(body) {
  return api.postData(basePath + "/admin-login", body);
}

/**
 * @typedef {{
 *   firstName: string;
 *   lastName: string;
 *   fullAddress: string;
 *   postalCode: number;
 *   city: string;
 *   province: string;
 *   country: string;
 *   occupation: string;
 *   birthday: string;
 *   gender: Man | Woman;
 *   email: string;
 *   phoneNumber: string;
 *   password: string;
 * }} CreateUser
 *
 * @param { CreateUser } body
 */
function signUp(body) {
  return api.postData(basePath + "/sign-up", body);
}

/**
 * @param {{
 *   typeCode: "email" | "phone-number";
 *   email: string;
 *   phoneNumber: string;
 *   code: string;
 * }} body
 */
function activateAccount(body) {
  return api.postData(basePath + "/activate-account", body);
}

/**
 * @param {{
 *   typeCode: "email" | "phone-number";
 *   email: string;
 *   phoneNumber: string;
 * }} body
 */
function resendActivationCode(body) {
  return api.postData(basePath + "/resend-activation-code", body);
}

/**
 * @param {{
 *   typeCode: "email";
 *   email: string; } | {
 *   typeCode: "phone-number";
 *   phoneNumber: string;
 * }} body
 */
function resendPasswordCode(body) {
  return api.postData(basePath + "/reset-password/resend-code", body);
}

/**
 * @param {{
 *   typeCode: "email";
 *   email: string; } | {
 *   typeCode: "phone-number";
 *   phoneNumber: string;
 * }} body
 */
function initResetPassword(body) {
  return api.postData(basePath + "/reset-password/init", body);
}

/**
 * @param {{ code: string; } & ({ typeCode: "phone-number"; phoneNumber: string; } | { typeCode: "email"; email: string; })} body
 */
function verifyPasswordCode(body) {
  return api.postData(basePath + "/reset-password/verify", body);
}

/**
 * @param {{ email: string; typeCode: "email"; code: string; password: string; }} body
 */
function resetPassword(body) {
  return api.postData(basePath + "/reset-password/reset", body);
}

export default authService;
