import "./App.css";
import { BrowserRouter, Routes } from "react-router-dom";
import injectRoutes from "./utils/router.utils";
import rootRoutes from "./routes/root.routes";
import authRoutes from "./routes/auth.routes";
import HeaderComponent from "./components/header.component";
import FooterComponent from "./components/footer.component";
import ModalComponent from "./components/modal.component";
import ToastComponent from "./components/toast/toast.component";
import userRoutes from "./routes/user.routes";
import transactionRoutes from "./routes/transaction.routes";

function App() {
  return (
    <>
      <BrowserRouter>
        <HeaderComponent />
        <div id="body-app">
          <Routes>
            {injectRoutes(authRoutes)}
            {injectRoutes(userRoutes)}
            {injectRoutes(transactionRoutes)}
            {injectRoutes(rootRoutes)}
          </Routes>
        </div>
        <FooterComponent />
        <ModalComponent />
        <ToastComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
