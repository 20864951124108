import { Link } from "react-router-dom";
import { useTranslate } from "../i18n/translation.i18n";

const NotFoundPage = () => {
  const { translate } = useTranslate();
  return (
    <div
      id="not-found-page"
      style={{ alignItems: "center", height: "100%", fontSize: "1.3rem" }}
    >
      <p align="center">
        {translate.pages.notFound.p_1_1}. <br />
        <Link to="/">{translate.pages.notFound.p_1_2}</Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
