import axios from "axios";
import apiConfig from "../config/api.config";

/**
 * apiClientBuilder function
 *
 * @template T, S
 *
 * @param {string} baseURL
 * @returns {{
 *   getData: {
 *     (
 *       pathReq: string, queryParams?: Record<string, string>, headers?: Record<string, string>
 *     ): Promise<S>
 *   };
 *   postData: {
 *     (
 *       pathReq: string, body?: T, queryParams?: Record<string, string>, headers?: Record<string, string>
 *     ): Promise<S>
 *   };
 *   putData: {
 *     (
 *       pathReq: string, body?: T, queryParams?: Record<string, string>, headers?: Record<string, string>
 *     ): Promise<S>
 *   };
 *   deleteData: {
 *     (
 *       pathReq: string, queryParams?: Record<string, string>, headers?: Record<string, string>
 *     ): Promise<S>
 *   };
 *   uploadFiles: {
 *     (
 *       pathReq: string, formData: FormData, queryParams?: Record<string, string>, headers?: Record<string, string>
 *     ): Promise<S>
 *   };
 * }}
 */
const apiClientBuilder = (baseURL) => {
  const api = [
    "diasporemit.com",
    "app.diasporemit.com",
    "client.diasporemit.com",
    "admin.diasporemit.com",
    "localhost:10600",
  ].includes(window.location.hostname)
    ? axios.create()
    : axios.create({ baseURL });

  const parsePayload = (payload) => {
    const searchParams = new URLSearchParams();
    Object.keys(payload).forEach((key) => {
      searchParams.append(key, payload[key]);
    });
    return searchParams;
  };

  const segment = "/-api-";

  const getBaseHeaders = () => {
    let authorization = "";
    if (localStorage.getItem("authToken")) {
      authorization = localStorage.authToken;
    } else if (sessionStorage.getItem("authToken")) {
      authorization = sessionStorage.authToken;
    } else {
      authorization = "no-token";
    }
    return {
      authorization,
      "content-type": "application/x-www-form-urlencoded",
    };
  };

  return {
    getData(pathReq, queryParams = undefined, headers = undefined) {
      return new Promise((resolve, reject) => {
        api
          .get(segment + pathReq, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    postData(
      pathReq,
      body = undefined,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .post(segment + pathReq, parsePayload(!body ? {} : body), {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    putData(
      pathReq,
      body = undefined,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .put(segment + pathReq, parsePayload(!body ? {} : body), {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    patchData(
      pathReq,
      body = undefined,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .patch(segment + pathReq, parsePayload(!body ? {} : body), {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    deleteData(pathReq, queryParams = undefined, headers = undefined) {
      return new Promise((resolve, reject) => {
        api
          .delete(segment + pathReq, {
            params: !queryParams ? {} : queryParams,
            headers: { ...getBaseHeaders(), ...(!headers ? {} : headers) },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },

    uploadFiles(
      pathReq,
      formData,
      queryParams = undefined,
      headers = undefined
    ) {
      return new Promise((resolve, reject) => {
        api
          .post(segment + pathReq, formData, {
            params: !queryParams ? {} : queryParams,
            headers: {
              ...getBaseHeaders(),
              "content-type": "multipart/form-data",
              ...(!headers ? {} : headers),
            },
          })
          .then(
            (response) => {
              if (response.data.failure) {
                reject(response.data);
                return;
              }
              if (response.data.error) {
                reject(response.data.error);
                return;
              }
              resolve(response.data.result);
            },
            (error) => reject(error)
          )
          .catch((err) => reject(err));
      });
    },
  };
};

export const api = apiClientBuilder(apiConfig.API_URL);
export const clientBuilder = apiClientBuilder;
