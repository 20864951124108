import { currentUserStore } from "../store/current-user.store";
import { api } from "../utils/api-config.utils";

const basePath = "/transaction";
const transactionService = {
  initTransaction,
  getTransactions,
  trackTransaction,
  useTransaction,
};

/**
 * @param {{
 *   quantity: number;
 *   coef: number;
 *   amount: number;
 *   currency: "CAD" | "USD";
 *   recipientFullName: string;
 *   recipientPhoneNumber: string;
 *   recipientEmail: string;
 * }} body
 */
function initTransaction(body) {
  return api.postData(
    basePath + "/init",
    { ...body, user: currentUserStore.snapshot.userId },
    null,
    {
      userId: currentUserStore.snapshot.userId,
    }
  );
}

function getTransactions({ page = 1, size = 15 }) {
  return api.getData(
    `${basePath}/list/admin`,
    { page, size },
    {
      userId: currentUserStore.snapshot.userId,
    }
  );
}

/** @param {{ transactionCode: string }} body */
function trackTransaction(body) {
  return api.postData(basePath + "/track", body, null, {
    userId: currentUserStore.snapshot.userId,
  });
}

/** @param { string } transactionId */
function useTransaction(transactionId) {
  const getTransaction = () => {
    return api.getData(`${basePath}/${transactionId}`, null, {
      userId: currentUserStore.snapshot.userId,
    });
  };

  const setStatus = () => {
    return api.postData(
      `${basePath}/${transactionId}/set-status`,
      {
        status: "PENDING",
      },
      null,
      {
        userId: currentUserStore.snapshot.userId,
      }
    );
  };

  /**
   * @param {{
   *   recipientFullName: string;
   *   recipientPhoneNumber: string;
   *   recipientEmail: string;
   * }} body
   */
  const update = (body) => {
    return api.postData(`${basePath}/${transactionId}/update`, body, null, {
      userId: currentUserStore.snapshot.userId,
    });
  };

  const cancel = () => {
    return api.postData(`${basePath}/${transactionId}/cancel`, {}, null, {
      userId: currentUserStore.snapshot.userId,
    });
  };

  const terminate = () => {
    return api.postData(`${basePath}/${transactionId}/terminate`, {}, null, {
      userId: currentUserStore.snapshot.userId,
    });
  };

  const remove = () => {
    return api.postData(`${basePath}/${transactionId}/remove`, null, {
      userId: currentUserStore.snapshot.userId,
    });
  };

  /**
   * @param { string } paymentCode
   */
  const savePaymentCode = (code) => {
    return api.postData(
      `${basePath}/${transactionId}/save-payment-code`,
      { code },
      null,
      {
        userId: currentUserStore.snapshot.userId,
      }
    );
  };

  return {
    setStatus,
    cancel,
    terminate,
    remove,
    update,
    getTransaction,
    savePaymentCode,
  };
}

export default transactionService;
