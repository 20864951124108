const ToastItemComponent = ({
  data,
  handleDissapear,
  handlerClose,
  handleClick,
}) => {
  const { toastType, toastTitle, toastMessage, interval } = data;
  const startTime = Date.now();
  let remainingTime = startTime;

  const defineTimeout = (time = interval * 1000) => {
    return setTimeout(() => {
      handleDissapear();
    }, time);
  };

  let timer = defineTimeout();

  const dissapear = (e) => {
    e.stopPropagation();
    click();
  };

  const click = () => {
    clearTimeout(timer);
    handleDissapear();
  };

  const pauseTimer = () => {
    remainingTime = Date.now();
    clearTimeout(timer);
  };

  const resumeTimer = () => {
    const newRemainigTime = interval * 1000 - (remainingTime - startTime);
    timer = defineTimeout(newRemainigTime);
  };

  return (
    <span
      onMouseEnter={pauseTimer}
      onMouseLeave={resumeTimer}
      className={`toast-item ${toastType}`}
    >
      <span className="toast-title" onClick={click}>
        {toastTitle}
      </span>
      <span className="toast-message" onClick={click}>
        {toastMessage}
      </span>
      <span className="close-toast" onClick={dissapear}></span>
    </span>
  );
};

export default ToastItemComponent;
