import axios from "axios";
import apiConfig from "../config/api.config";
import apiKeysConfig from "../config/api-keys.config";

const deeplClient = axios.create({ baseURL: apiConfig.DEEPL_BASE_URL });

/**
 * @type {{ translate: (text: string, lang: string) => Promise<string> }}
 */
const deepl = {
  translate: async (
    /** @type { string } */ text,
    /** @type { string } */ lang
  ) => {
    try {
      return await deeplClient
        .post(
          "/v2/translate",
          { text, target_lang: lang },
          {
            headers: {
              authorization: `DeepL-Auth-Key ${apiKeysConfig.DEEPL_AUTH_KEY}`,
              "User-Agent": "DiaspoRemit/1.0.0",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => response.data.translations[0].text);
    } catch (error) {
      console.error(error);
      return text;
    }
  },
};

export default deepl;
