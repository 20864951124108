import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import transactionService from "../../services/transaction.service";
import toast from "../../utils/toast.utils";
import { useTranslate } from "../../i18n/translation.i18n";
import { currentUserStore } from "../../store/current-user.store";

const TransactionPage = () => {
  const { translate } = useTranslate();
  const [
    transaction = {
      id: null,
      transactionCode: null,
      user: {
        fullName: null,
        fullAddress: null,
        email: null,
        phoneNumber: null,
        postalCode: null,
        occupation: null,
        city: null,
        province: null,
        country: null,
      },
      quantity: null,
      coef: null,
      amount: null,
      currency: null,
      paymentCode: null,
      recipientFullName: null,
      recipientPhoneNumber: null,
      recipientEmail: null,
      status: null,
      feesExchange: null,
      createdAt: null,
      updatedAt: null,
    },
    setTransaction,
  ] = useState();
  const [isPending, setIsPending] = useState(false);
  const { transactionId } = useParams();
  const { getTransaction, cancel, setStatus, terminate } =
    transactionService.useTransaction(transactionId);

  const pullTransaction = async () => {
    setIsPending(true);

    try {
      const t = await getTransaction();
      setTransaction((prevTrensaction) => ({ ...prevTrensaction, ...t }));
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      toast.dispatch("error", "Error", err.message || err);
    }
  };

  useEffect(() => {
    pullTransaction();
  }, []);

  const cancelTransaction = () => {
    cancel()
      .then(() => {
        setTransaction({ ...transaction, status: "CANCELLED" });
        toast.dispatch("success", "Success", "Transaction cancelled.");
      })
      .catch((err) => {
        setIsPending(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const pendingTransaction = () => {
    setStatus()
      .then(() => {
        setTransaction({ ...transaction, status: "PENDING" });
        toast.dispatch("success", "Success", "Transaction pended.");
      })
      .catch((err) => {
        setIsPending(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const terminateTransaction = () => {
    terminate()
      .then(() => {
        setTransaction({ ...transaction, status: "COMPLETED" });
        toast.dispatch("success", "Success", "Transaction completed.");
      })
      .catch((err) => {
        setIsPending(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  return (
    <div
      id="track-transaction-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "15px 0",
      }}
    >
      <strong
        align="center"
        style={{
          display: "inline-block",
          width: "100%",
          margin: "10px 0 20px 0",
        }}
      >
        {translate.pages.transaction.strong}
      </strong>
      {isPending && <p>{translate.pages.transaction.loading}</p>}
      {!isPending && (
        <>
          <table style={{ fontWeight: 400, padding: "10px", width: "100%" }}>
            <tbody>
              <tr style={{ marginTop: "5px" }}>
                <td align="left">{translate.pages.transaction.td_1}</td>
                <td align="left">{transaction.transactionCode}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_2}</td>
                <td align="left">
                  <b>{translate.transactionStatus[transaction.status]}</b>
                </td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.paymentCode}</td>
                <td align="left">
                  <b>{transaction.paymentCode}</b>
                </td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_3}</td>
                <td align="left">{transaction.recipientFullName}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_4}</td>
                <td align="left">{transaction.recipientEmail}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_5}</td>
                <td align="left">{transaction.recipientPhoneNumber}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_6}</td>
                <td align="left">
                  {transaction.currency} {transaction.quantity}
                </td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_7}</td>
                <td align="left">{transaction.currency}</td>
              </tr>
              <tr>
                <td align="left">1 {transaction.currency}</td>
                <td align="left">{transaction.coef} XAF</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_8}</td>
                <td align="left">{transaction.feesExchange}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_9} (XAF)</td>
                <td align="left">{transaction.amount}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.td_10}</td>
                <td align="left">
                  {new Date(transaction.createdAt).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
          <hr style={{ margin: "5px 0 10px 0" }} />
          <table style={{ fontWeight: 400, padding: "10px", width: "100%" }}>
            <tbody>
              <tr>
                <td align="left">{translate.pages.transaction.names}</td>
                <td align="left">{transaction.user.fullName}</td>
              </tr>
              <tr>
                <td align="left">{translate.pages.transaction.senderEmail}</td>
                <td align="left">{transaction.user.email}</td>
              </tr>
              <tr>
                <td align="left">
                  {translate.pages.transaction.senderPhoneNumber}
                </td>
                <td align="left">{transaction.user.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <div id="more-options" style={{ width: "100%", padding: "15px" }}>
        <h4 style={{ margin: 0, padding: 0 }}>
          {translate.pages.transaction.h4_1}
        </h4>
        <hr style={{ margin: "5px 0 10px 0" }} />
        {transaction.status === "INITIATED" &&
          currentUserStore.snapshot.roles.includes("admin") && (
            <button className="button" onClick={cancelTransaction}>
              {translate.pages.transaction.cancel}
            </button>
          )}
        {transaction.status === "PAID" &&
          currentUserStore.snapshot.roles.includes("admin") && (
            <button className="button" onClick={pendingTransaction}>
              {translate.pages.transaction.pending}
            </button>
          )}
        {transaction.status === "PENDING" && (
          <button className="button" onClick={terminateTransaction}>
            {translate.pages.transaction.complete}
          </button>
        )}
      </div>
      <div
        id="more-options"
        style={{ width: "100%", marginTop: "15px", padding: "15px" }}
      >
        <h4 style={{ margin: 0, padding: 0 }}>
          {translate.pages.userProfile.h4_1}
        </h4>
        <hr style={{ margin: "5px 0 10px 0" }} />
        <Link to="/transactions/track">
          {translate.pages.userProfile.link_2}
        </Link>
        <br />
        <Link to="/transactions">{translate.pages.userProfile.link_3}</Link>
        <br />
      </div>
    </div>
  );
};

export default TransactionPage;
