const apiConfig = {
  API_URL: [
    "diasporemit.com",
    "app.diasporemit.com",
    "admin.diasporemit.com",
    "localhost:10600",
  ].includes(
    window.location.hostname
  )
    ? ""
    : "http://localhost:10600/",
  WEBSOCKET_URL: "",
  DEEPL_BASE_URL: "https://api-free.deepl.com",
};

export default apiConfig;
