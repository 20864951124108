import { currentUserStore } from "../store/current-user.store";
import { api } from "../utils/api-config.utils";

const basePath = "/user";
const userService = {
  logout,
  changePassword,
  changeEmail,
};

function logout() {
  return api.postData(basePath + "/logout", {}, null, {
    userId: currentUserStore.snapshot.userId,
  });
}

/** @param {{ newPassword: string; oldPassword: string; email: string; }} body */
function changePassword(body) {
  return api.postData(basePath + "/change-password", body, null, {
    userId: currentUserStore.snapshot.userId,
  });
}

/** @param {{ currentEmail: string; newEmail: string; }} body */
function changeEmail(body) {
  return api.postData(basePath + "/change-email", body, null, {
    userId: currentUserStore.snapshot.userId,
  });
}

export default userService;
